import { BehaviorSubject } from "rxjs";
import { PagedList } from "../core/model/paged-list";
import { Injectable } from "@angular/core";

@Injectable()
export class SubscriptionsService {
  public BancoDuvidasPageTickets = new BehaviorSubject<PagedList>(
    new PagedList(1, 10, "")
  );
}
