export class PagedList {
  /**
   *
   */
  constructor(pageNumber: number, pageSize: number, filter: string) {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;

    if (filter == undefined) {
      this.filter = "";
    } else {
      this.filter = filter;
    }
  }

  count: number;
  firstItemOnPage: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  isFirstPage: boolean;
  isLastPage: boolean;
  lastItemOnPage: number;
  pageCount: number;
  pageNumber: number;
  pageSize: number;
  totalItemCount: number;
  filter: string;
}
