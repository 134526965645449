import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{
		path: 'admin',
		loadChildren: './admin/admin.module#AdminModule',
	},
	{
		path: '',
		loadChildren: './cliente/cliente.module#ClienteModule',
	},
	{
		path: '**',
		redirectTo: 'admin'
	}];

@NgModule({
	imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
