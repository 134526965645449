import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JwtModule, JwtModuleOptions } from "@auth0/angular-jwt";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import {
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CommonModule } from "@angular/common";
import { ToastrModule } from "ngx-toastr";
import { SubscriptionsService } from "./servicos/subscriptions.service";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export function getToken() {
  return localStorage.getItem("token");
}

const JWT_Module_Options: JwtModuleOptions = {
  config: {
    tokenGetter: getToken,
    whitelistedDomains: ["localhost:4200"],
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    JwtModule.forRoot(JWT_Module_Options),
  ],
  exports: [],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    SubscriptionsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
